<template>
    <div>
        <div>
            <v-row>
                <v-col>
                    <v-container grid-list-xs>
                        <v-row>
                            <v-col class="text-center">
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th v-for="(item, index) in headers" :key="index" class="text-left">
                                                    {{ item.text }}
                                                </th>
                                                <th>Opciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in limnimeterShow" :key="index">
                                                <td v-for="(field, index) in headers" :key="`${index}-tbody`"
                                                    class="text-left">
                                                    <template>
                                                        {{ item[field.value] }}
                                                    </template>
                                                </td>
                                                <td class="text-left">Editar - Eliminar
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </div>
        <div>
            <LimnimeterListPagination :currentPage="currentPage" :totalPages="totalPages"
                @update:currentPage="currentPage = $event" />
        </div>

    </div>

</template>


<script>

import { mapState } from 'vuex';
import LimnimeterListPagination from './LimnimeterListPagination.vue';

export default {
    name: 'limnimeter-list',
    data() {
        return {
            headers: [
                { text: 'Nombre', value: 'name' },
                { text: 'Estado', value: 'stateShow' },
                { text: 'Fecha de Instalación', value: 'installation_date' }
            ],
            currentPage: 1,
            itemsPerPage: 5,
        };
    },
    props: {
        limnimetersList: {
            type: Array,
            required: true
        }
    },
    components: {
        LimnimeterListPagination
    },
    computed: {
        ...mapState({
            limnimeters: state => state.limnimetersControl.limnimeters,
        }),
        limnimeterShow() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            let limnimetersFiltered = this.$utils.deepCopy(this.limnimetersList).map((limnimeter) => {
                limnimeter.stateShow = limnimeter.state ? 'Activo' : 'Inactivo'
                return limnimeter
            })
            return limnimetersFiltered.slice(start, end)
        },
        totalPages() {
            let limnimeterList = this.$utils.deepCopy(this.limnimetersList).map((limnimeter) => {
                limnimeter.stateShow = limnimeter.state ? 'Activo' : 'Inactivo'
                return limnimeter
            })
            return Math.ceil(limnimeterList.length / this.itemsPerPage);
        },
        paginatedData() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.items.slice(start, end);
        },
    },

};
</script>