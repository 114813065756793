<template>
    <div>
        <v-row>
            <v-col>
                <a>Limnimetros</a>
            </v-col>
        </v-row>
        <v-row class="pt-3">
            <v-col cols="5">
                <h2 class="text-h4">
                    Lista de Limnímetros
                </h2>
            </v-col>
            <v-col cols="7">
                <LimnimeterListFilter :filters="filters" :limnimeters_state_options="options_state" />
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <LimnimeterList :limnimetersList="limnimetersFiltered" />
            </v-col>
        </v-row>
    </div>
</template>

<script>

import LimnimeterList from '@/components/LimnimeterList';
import LimnimeterListFilter from '../../components/LimnimeterListFilter.vue';
import { mapState, mapActions } from 'vuex';

export default {
    components: {
        LimnimeterList,
        LimnimeterListFilter
    },
    data() {
        return {
            filters: {
                name: '',
                state: null,
                code: ''
            },

            options_state: [
                {
                    code: null,
                    name: 'Todos',
                },
                {
                    code: 'true',
                    name: 'Activo',
                },
                {
                    code: 'false',
                    name: 'Desactivado',
                },
            ],
        }
    },
    computed: {
        ...mapState({
            limnimeters: state => state.limnimetersControl.limnimeters,
        }),
        limnimetersFiltered() {
            let limnimetersFiltered = this.$utils.deepCopy(this.limnimeters).filter((limnimeter) => {
                const matchesName = limnimeter.name.toLowerCase().includes(this.filters.name.toLowerCase())
                const matchesState = this.filters.state === null || this.filters.state === '' || String(limnimeter.state) === this.filters.state;
                return matchesName && matchesState
            })
            return limnimetersFiltered
        }
    },
    methods: {
        ...mapActions('limnimetersControl', ['loadLimnimeters'])
    },
    async mounted() {
        await this.loadLimnimeters()
    },
}
</script>