<template>
    <v-row>
        <v-col>
            <v-text-field v-model="filters.name" dense solo label="Nombre"
                hide-details="auto"></v-text-field>
        </v-col>

        <v-col>
            <v-select v-model="filters.state" dense solo :items="limnimeters_state_options" item-text="name"
                item-value="code" label="Por estado" hide-details="auto"></v-select>
        </v-col>

    </v-row>
</template>

<script>
export default {
    name: 'irrigator-list-filter',
    props: {
        filters: {
            type: Object
        },
        limnimeters_state_options: {
            type: Array
        }
    },
};
</script>
